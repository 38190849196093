import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
//import Step3 from "./components/Step3";
//import Step4 from "./components/Step4";
import { Button, Steps, Spin, Row, Select, Image, Empty, Form, Card, Input } from "antd";
//import { CarouselRef } from "antd/lib/carousel";
//import ViewOfferStep from "./components/ViewOfferStep";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  offerAmountState,
  selectedOfferState,
  UserProfileState,
  offerListState,
  accountListState,
  selectedAccountState,
} from "../../atoms";
import { acceptOfferApi, getOffers } from "../../api/offer";
import { extractErrors, formatAsCurrency, showError, showMessage, thousandSeparator } from "../../helpers";
import { getAccounts } from "../../api/bank";
import { IAccount, IOfferItem } from "../../api/types";
import { toast } from "react-toastify";
import { Util } from "../../utils/Utils";
import Step1 from "./components/Step1";
const { Step } = Steps;

const moment = require("moment");
const { Option } = Select;
const LoanApply = () => {
  const [showAMountField, setShowAMountField] = useState(true);
  const [showOffers, setShowOffers] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const navigate = useNavigate();
  const user = useRecoilValue(UserProfileState);
  const [accounts, setAccounts] = useRecoilState(accountListState);
  const [offers, setOffers] = useRecoilState(offerListState);
  const [loading, setLoading] = useState(false);
  const [amount, setOfferAmount] = useRecoilState(offerAmountState);
  const [selectedOffer, setOffer] = useRecoilState(selectedOfferState);
  const [selectedAccount, setAccount] = useRecoilState(selectedAccountState);
  /*useEffect(() => {
    setLoading(true);
    getAccounts(user?.msisdn).then(response => {
      // Update the state with the fetched data
      console.log(response.data);
      if (!response.data.errorFlag) {
        const accounts = response.data?.data as IAccount[];
        setAccounts(accounts);
      } else {
        toast.warning(response.data.responseMessage);
      }
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.error(error ? error?.message : "Unable to complete action");
      });
  }, [setAccount]);

  const [banks, setBanks] = useRecoilState(banksState);
   useEffect( () => {
       setLoading(true);
     getBanks().then(response => {
         // Update the state with the fetched data
         setBanks(response.data);
         //setBanks([...banks, response.data)]);
       })
       .catch(error => {
         toast(error ? error?.message : "Unable to complete action", {
           type: "error",
         });
         console.log(error);
         // Handle error
         console.error('Error fetching data:');
       });
       setLoading(false);
   }, [setBanks]);
 */
  const onFinish = async (values: String) => {
    console.log('Form values:', values);
    try {
      if (loading) return;
      setLoading(true);
      const response = await getOffers(amount, process.env.NODE_ENV === "production" ? user?.msisdn : "2347036264305");
      console.log(response.data);
      if (response.data.errorFlag == false) {
        const listOfOffers = response.data?.data as IOfferItem[];
        setOffers(listOfOffers);
        setShowOffers(true);
        setShowAMountField(false);
        // return response.data?.data as IOfferItem[];
      } else {
        toast.error(response.data.responseMessage);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigate("/");
      }
      var errorMsg = extractErrors(error);
      toast.error(errorMsg);
      return;
    }
    // setOfferAmount("");
  };
  const handleClick = () => {
    if (!selectedOffer && selectedOffer == null)
      return toast.error("Please select an offer to continue");
    if (!selectedAccount && selectedAccount == null)
      return toast.error("Please select destination account");
    setShowAMountField(false);
    setShowOffers(false);
    setShowPayment(true);
  };
  return (
    <div className="loan-apply-wrapper">
      <div className="container ">
        <div className="row w-100 mb-3">
          <div className="col-md-12 w-100">
            <div className="small-spacer">
              {/* loading && <div className="overlay"><Spin /></div> */}
              {showAMountField && <div className="form-width">
                <Card title="Enter Loan Amount" className="rounded shadow-sm">
                  <Form layout="vertical" onFinish={onFinish} >
                    <Form.Item
                      name="amount"
                      label="Enter loan amount"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Amount"
                        onChange={(e) =>
                          setOfferAmount(`${Number(e.target.value) * 100}`)
                        }
                        value={thousandSeparator(amount)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary"
                        htmlType="submit"
                        className="atn-btn atn-btn-primary"
                        loading={loading}
                        disabled={loading}
                        block
                      >
                        Get Offer
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>}
              {showOffers && <div className="form-width">
                {
                  offers.length > 1 ?
                    <Step1 handleClick={handleClick} /> : <Empty />}
              </div>
              }
              {showPayment && <div className="form-width">
                {
                  selectedOffer && selectedAccount ?
                    <Step2 /> : <Empty />}
              </div>
              }
            </div >
          </div>
        </div>
      </div>
    </div >
  );
};



/*
const LoanApply = () => {
  const [...offers] = useRecoilValue(offerListState);
  const [currentStep, setStep] = useState(0);
  const carouselRef = useRef<CarouselRef>() as RefObject<CarouselRef>;

  const [selectedLoan, setLoan] = useRecoilState(selectedOfferState);

  const [selectedBank, setBank] = useRecoilState(selectedBankState) as any;

  const [selectedCard, setCard] = useRecoilState(selectedCardState);

  const [amount, setAmount] = useRecoilState(offerAmountState);

  const handleOnNext = (data: any) => {
    console.log(data)
    if (currentStep === 0 && !amount) {
      return showMessage("Loan Amount is required", "Please enter loan amount", "info");
    }
    if (offers.length < 1) {
      setStep(0);
      return showMessage("Offer", "No offer at the moment", "info");
    }
    if (currentStep === 1 && !selectedLoan) {
      return showMessage(
        "Loan offer not selected",
        "Please you must select a loan offer",
        "info"
      );
    }

    if (currentStep === 3 && !selectedBank) {
      return showMessage("Bank not selected", "Please select a bank", "info");
    }
    if (currentStep === 4) return acceptOffer();

    setStep((state) => state + 1);
  };



  const handlePrevious = () => {
    if (currentStep === 0) return;
    setStep((step) => step - 1);
  };

  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(UserProfileState);

  const acceptOffer = async () => {
    try {
      setLoading(true);

      const response = await acceptOfferApi({
        offerId: selectedLoan?.offerId,
        phoneNumber: user?.data?.msisdn,
        bankId: selectedBank?.id,
        cardId: selectedCard?.id,
      });

      console.log(response.data);

      setBank(null);
      setCard(null);
      setLoan(null);

      setLoading(false);

      setAmount("");

      setStep(0);

      showMessage(
        "Operation successful",
        "Loan successfully created",
        "success"
      );
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    carouselRef.current?.goTo(currentStep);
  }, [currentStep]);

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <Step1 onNextStep={handleOnNext} />;
      case 1:
        return <ViewOfferStep onNextStep={handleOnNext} />;
      case 2:
        return <Step2 onNextStep={handleOnNext} />;
      case 3:
        return <Step3 onNextStep={handleOnNext} />;
      case 4:
        return <Step4 onNextStep={handleOnNext} />;
      default:
        return null;
    }
  };

  return (
    <div className="loan-apply-wrapper">
      <Steps current={currentStep} className="loan-apply-steps">
        <Step title="Enter amount" />
        <Step title="View offers" />
        <Step title="Bank account" />
        <Step title="Debit card" />
        <Step title="Confirmation" />

      </Steps>
      <div className="container ">
        <div className="row w-100 mb-3">
          <div className="col-md-12 w-100">{renderStep()}</div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-3 ">
          <div className="col-md-12">
            <div className="text-right">
              {currentStep > 0 && (
                <button className="atn-btn mr-2" onClick={handlePrevious}>
                  Previous
                </button>
              )}
              <Button
                onClick={handleOnNext}
                className="atn-btn atn-btn-primary"
                disabled={loading}
                loading={loading}
              >
                {currentStep === 4 ? "Confirm" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};*/


export default LoanApply;
