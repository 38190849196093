import "./App.css";
import "antd/dist/antd.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/login";
import VerifyPage from "./pages/verify";
import LoanApply from "./pages/LoanApply/LoanApply";
import DashboardHOC from "./components/Dashboard";
import BankAccount from "./pages/bankaccounts/BankAccount";
import HistoryPage from "./pages/Payment/History/history";
import PaymentCardPage from "./pages/PaymentCard";
import FaqPage from "./pages/faq";
import TermsPage from "./pages/terms";
import DashboardPage from "./pages/dashboard";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Storage } from "./storage";
import PaybackPage from "./pages/Payment/Payback";
import PaymentPage from "./pages/Payment/History/payment";

const queryClient = new QueryClient();

const HomeHOC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = Storage.getUser();
    if (user) return navigate("/dashboard");
  }, []);
  return <Outlet />;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeHOC />}>
              <Route index element={<LoginPage />} />
              <Route path="/verify" element={<VerifyPage />} />
            </Route>
            <Route path="/dashboard" element={<DashboardHOC />}>
              <Route index element={<LoanApply />} />
              <Route path="bank-accounts" element={<BankAccount />} />
              <Route path="payback" element={<PaybackPage />} />
              <Route path="history" element={<HistoryPage />} />
              <Route path="history/:id/pay" element={"id" == "id" ? <PaymentPage /> : <Navigate to="/" />} />
              <Route path="payment-cards" element={<PaymentCardPage />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="terms" element={<TermsPage />} />
            </Route>
            <Route path="*" element={<Navigate to='/' />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
