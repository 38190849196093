import { UserCredential } from "firebase/auth";

export class Storage {
  static saveUser(user: UserCredential | undefined | null) {
    if (!user) return;

    localStorage.setItem("cashtoken_user", JSON.stringify(user));
  }

  static getUser() {
    const user = localStorage.getItem("cashtoken_user");
    if (!user) return null;

    return JSON.parse(user) as UserCredential;
  }

  static removeUser() {
    localStorage.removeItem("cashtoken_user");
  }

  static saveToken(token: string | undefined | null) {
    if (!token) return;
    console.log(token);
    localStorage.setItem("token", JSON.stringify(token));
  }

  static getToken() {
    const token = localStorage.getItem("token");
    if (!token) return null;
    return JSON.parse(token) as String;
  }
  static removeToken() {
    localStorage.removeItem("token");
  }
  static removeSessionItems() {
    localStorage.clear();
  }
}
