import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Empty, Row, Spin, Tag } from "antd";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { getUserProfile } from "../../api/user";
import { selectedCardState, UserProfileState } from "../../atoms";
import AddCard from "./AddCard/add-card";

const ATMCards = () => {
  const [isAddBankOpen, setAddBankOpen] = useState(false);
  const onCancel = () => {
    setAddBankOpen(false);
  };
  const [card, setCard] = useRecoilState(selectedCardState);

  const queryClient = useQueryClient();

  const user = useRecoilValue(UserProfileState);

  const cardResponse = useQuery({
    queryKey: ["cards"],
    queryFn: async () => {
      const response = await getUserProfile(user?.data?.msisdn);
      return response.data;
    },
  });

  const onOk = () => {
    queryClient.invalidateQueries({ queryKey: ["cards"] });
    setAddBankOpen(false);
  };
  const renderCard = () => {
    return (cardResponse?.data?.data.cards || []).map((item: any, i: any) => (
      <Col key={i} xl={8} sm={24}>
        <div
          onClick={() => setCard(item)}
          className={`shadow-sm bg-white rounded px-3 py-2 cursor-pointer ${
            card?.id === item.id ? "selected" : ""
          }`}
        >
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                {/* <h3 className="fs-md text-dark">{item.cardOwner}</h3> */}
                <h3 className="fs-md text-dark">
                  <Tag>Active</Tag>
                </h3>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <h3 className="fs-sm card-pan ">XXXX</h3>
                <h3 className="fs-sm card-pan">XXXX </h3>
                <h3 className="fs-sm card-pan">XXXX</h3>
                <h3 className="fs-sm card-pan">{item.panLast4Digits}</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <h3 className="fs-sm text-dark">
                  {item?.tokenExpiryDate.substring(
                    item?.tokenExpiryDate.length - 2,
                    item?.tokenExpiryDate.length
                  )}
                  /{item?.tokenExpiryDate.substring(0, 2)}
                </h3>
                {item?.cardType?.toLowerCase() === "mastercard" ? (
                  <div className="debit-card-wrapper">
                    <img
                      src="https://my.migo.money/assets/icons/logos/Master-card.svg"
                      alt="master-card"
                    />
                  </div>
                ) : (
                  <div className="debit-card-wrapper">
                    <img
                      src="https://my.migo.money/assets/icons/logos/Verve.png"
                      alt="verve-card"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    ));
  };

  if (cardResponse.isLoading)
    return (
      <Row className="my-3" justify="center">
        <Spin />
      </Row>
    );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row justify="end">
            <Col>
              <Button
                onClick={() => setAddBankOpen(true)}
                className="atn-btn atn-btn-primary"
              >
                Add Card
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="py-3"></div>
      <div className="row">
        <div className="col-md-12">
          <Row gutter={[16, 20]}>{renderCard()}</Row>
        </div>
      </div>
      <AddCard isOpen={isAddBankOpen} onCancel={onCancel} onOK={onOk} />
    </div>
  );
};

export default ATMCards;
