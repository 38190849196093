import { Button } from "antd";
import { useEffect, useState } from "react";
import { auth, signUserWithPhoneNumber } from "../firebase";
import { RecaptchaVerifier } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Util } from "../utils/Utils";
import { useRecoilState } from "recoil";
import { verifyPhoneNumberAtom } from "../atoms";

let recaptchaVerifier!: RecaptchaVerifier;
// let window: any;
declare const window: any;

const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useRecoilState(verifyPhoneNumberAtom);
  const [isLoading, setLoading] = useState(false);

  const handleOnLogin = async () => {
    try {
      if (isLoading) return;
      const pNumber = Util.formatPhoneNumber(phoneNumber);
      setLoading(true);

      const confirmatonResult = await signUserWithPhoneNumber(
        pNumber,
        recaptchaVerifier
      );

      setValue({ phoneNumber, confirmationResult: confirmatonResult });

      setLoading(false);
      return navigate("/verify", {
        state: {
          phoneNumber,
          confirmatonResult: JSON.stringify(confirmatonResult),
        },
      });
    } catch (error: any) {
      console.log("error> ", error?.message);
      toast(error ? error?.message : "Unable to complete action", {
        type: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (recaptchaVerifier) return;

    recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response: any) => {},
      },
      auth
    );
    recaptchaVerifier.render();
  }, []);
  return (
    <div className="login-wrapper-container">
      <div className="login-col right d-flex align-items-center">
        <div className="form-wrapper">
          <div className="container">
            <div className="col-md-12 h-100">
              <div className="login-form h-100">
                <h1 className="login-form-title">Login</h1>
                <form className="w-100">
                  <div className="d-flex bg-white mb-3 form-input">
                    <div className="country-code-wrapper">+234 (NG)</div>
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      placeholder="Your phone number"
                    />
                  </div>
                  <div id="recaptcha-container"></div>
                  <br />
                  <Button
                    className="atn-btn atn-btn-primary"
                    onClick={() => handleOnLogin()}
                    loading={isLoading}
                  >
                    Login
                  </Button>
                  <br />
                  <article className="text-left article-text">
                    For the safety of your personal data, there will be an
                    additional verification step on the next page.
                  </article>
                  <article className="text-center article-text">
                    You will receive an OTP code to verify your number.
                  </article>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
