import { Storage } from "../storage";

export class Util {
  static formatPhoneNumber(phoneNumber: string) {
    let pNumber;

    if (phoneNumber.startsWith("+")) pNumber = phoneNumber;
    else if (phoneNumber.startsWith("0"))
      pNumber = `+234${phoneNumber.substring(1, phoneNumber.length)}`;
    else if (phoneNumber.startsWith("234")) pNumber = `+${phoneNumber}`;
    else pNumber = `+234${phoneNumber}`;

    return pNumber;
  }

  static sanitizePhoneNumber(phoneNumber: string) {
    if (phoneNumber.startsWith("+"))
      return phoneNumber.substring(1, phoneNumber.length);
    return phoneNumber;
  }

  static formatAsCurrency = (number: any) => {
    return Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(number);
  };

  static nairaToKobo = (amount: string) => {
    if (amount == null)
      return "100";
    return amount + "00";
  };

  static convertStringToNumber = (string: string) => {
    return parseInt(string);

  };

  static handleLogout = () => {
    Storage.removeUser();
    Storage.removeSessionItems();
    window.location.href = "/";
  };
}
