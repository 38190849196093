import axios from "axios";
import { Storage } from "../storage";
import { showError, showMessage } from "../helpers";
export const BASE_URL = "http://127.0.0.1:8000/live/api/v1/1cash/"
export const BASE_URL_LIVE = "https://api.1cash.com.ng/live/api/v1/1cash/"
export const Axios = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? BASE_URL_LIVE : BASE_URL,
});
Axios.interceptors.request.use(
  async (config) => {
    if (!navigator.onLine) {
      // If there is no internet connection, cancel the request
      showMessage("Network Error!", "please check your internet", "error");
      return Promise.reject(new Error('No internet connection'));
    }
    const token = Storage.getToken();
    const auth = token ? `Bearer ${token}` : '';
    if (token) {
      console.log(token);
      config.headers?.common?.set('Authorization', auth)
    }
    return config;
  },
  function (error) {
    showError(error);
    return Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    showError(error);
    return Promise.reject(error);
  }
);