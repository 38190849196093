import { showError } from "../helpers";
import { Storage } from "../storage";
import { Util } from "../utils/Utils";
import { Axios } from "./base";


export const login = (phoneNumber: string) => {
  const formData = new FormData();
  formData.append('username', phoneNumber);
  formData.append('password', 'secret');
  return Axios.postForm(`token`, formData);
};
export const getUserProfile = (phoneNumber: string) => {
  var token = Storage.getToken();
  return Axios.get(`profile/${phoneNumber}`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const getUserLoanHistory = (phoneNumber: string) => {
  var token = Storage.getToken();
  return Axios.get(`loan/history/${phoneNumber}`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const updateUserEmail = (payload: Object) => {
  var token = Storage.getToken();
  return Axios.put(`user/update`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
