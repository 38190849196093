import { showError } from "../helpers";
import { Storage } from "../storage";
import { Util } from "../utils/Utils";
import { Axios } from "./base";

/*Axios.interceptors.response.use(
  (value) => Promise.resolve(value),
  (error) => {
    if(error.response.status === 401) return  Util.handleLogout();
    showError(error);
    return Promise.reject(error);
  }
);*/

export const getAccounts = (customerId: string) => {
  var token = Storage.getToken();
  return Axios.get(`${customerId}/accounts`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const getBanks = () => {
  var token = Storage.getToken();
  return Axios.get(`banks`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const validateBank = (payload: any) => {
  var token = Storage.getToken();
  return Axios.post(`validate/accountNumber`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const addAccountNumber = (payload: any) => {
  var token = Storage.getToken();
  return Axios.post(`add/accountNumber`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const validateCard = (payload: any) => {
  var token = Storage.getToken();
  return Axios.post("validate/card", payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const validateCardOtp = (payload: any) => {
  console.log(payload);
  var token = Storage.getToken();
  return Axios.post("validate/card/otp", payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
