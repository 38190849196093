import { useQuery, } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Row,
  Spin,
  Table, DatePicker, Space
} from "antd";
import type { DatePickerProps } from 'antd';
import { useMemo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getLoanHistory } from "../../../api/offer";
import { selectLoan, UserProfileState, loansList } from "../../../atoms";
import { Util } from "../../../utils/Utils";
import { toast } from "react-toastify";
const moment = require("moment");

const HistoryPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(UserProfileState);
  const [, setLoan] = useRecoilState(selectLoan);
  const [loans, setLoans] = useRecoilState(loansList);
  const memo = moment();
  var queryParam = {
    msisdn: user.msisdn,
    startdate: memo.startOf('month').format('YYYY-MM-DD'),
    lastdate: memo.endOf('month').format('YYYY-MM-DD')
  }
  /*const { isLoading, error, data } = useQuery(["hloans",
    async () => {
      const memo = moment();
      const startdate = memo.startOf('month').format('YYYY-MM-DD');
      const lastdate = memo.endOf('month').format('YYYY-MM-DD');
      console.log(startdate, lastdate);
      await getLoanHistory(user?.msisdn, startdate, lastdate);
      // return response.data;
    },])*/
  //const { isLoading, error, data } = useQuery(['hloans', queryParam], () => getLoanHistory(queryParam.msisdn, queryParam.startdate, queryParam.lastdate))
  // console.log(data?.data);
  // setLoans(data?.data.data);
  useEffect(() => {
    setLoading(true);
    getLoanHistory(queryParam.msisdn, queryParam.startdate, queryParam.lastdate).then(response => {
      // Update the state with the fetched data
      setLoading(false);
      console.log(response.data);
      setLoans(response.data.data);
    })
      .catch(error => {
        setLoading(false);
        console.log(error);
        if (error.response.status === 401) {
          Util.handleLogout();
          navigate("/");
        }
        toast.error(error ? error?.message : "Unable to complete action");
      });
  }, []);
  /* const { isLoading, error, data } = useQuery({
     queryKey: ["hloans", user?.msisdn],
     queryFn: async () => {
       const memo = moment();
       const startdate = memo.startOf('month').format('YYYY-MM-DD');
       const lastdate = memo.endOf('month').format('YYYY-MM-DD');
       console.log(startdate, lastdate);
       await getLoanHistory(user?.msisdn, startdate, lastdate);
       // return response.data;
     },
   });*/
  // var loans: any[] = data || [];
  const refresh = () => window.location.reload();

  const columns = useMemo(
    () => [
      /*{
        title: "Loan ID",
        dataIndex: "loanReferenceId",
        key: "loanReferenceId",
      },*/
      {
        title: "Customer ID",
        dataIndex: "customerId",
        key: "customerId",
      },
      {
        title: "Amount Borrowed",
        dataIndex: "amount",
        key: "amount",
        render: (keyValue: string) =>
          Util.formatAsCurrency(Number(keyValue) / 100),
      },
      {
        title: "Amount Payable",
        dataIndex: "amountPayable",
        key: "amountPayable",
        render: (keyValue: string) =>
          Util.formatAsCurrency(Number(keyValue) / 100),
      },
      {
        title: "Request Date",
        dataIndex: "dateCreated",
        key: "dateCreated",
        render: (value: Number) => moment(value).format("ll")
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        render: (value: Number) => moment(value).format("ll")
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Action",
        dataIndex: "",
        render: (keyValue: any, data: any) => (
          data.status.toUpperCase() === "CLOSED" ?
            data.providerCode.toUpperCase() == "LME" ?
              <Link onClick={() => setLoan(data)} to={`${data.id}/pay`}>
                <Button className="atn-btn atn-btn-primary">Pay</Button>
              </Link> :
              <a
                href={`https://www.quickteller.com/ils`} target="_blank">
                <Button className="atn-btn atn-btn-primary">Pay</Button>
              </a>
            : <div></div>
        ),
      },
    ],
    []
  );
  const onChange: DatePickerProps['onChange'] = async (date, dateString) => {
    try {
      const startdate = moment(dateString).startOf('month').format('YYYY-MM-DD');
      const lastdate = moment(dateString).endOf('month').format('YYYY-MM-DD');
      setLoading(true);
      const response = await getLoanHistory(user?.msisdn, startdate, lastdate);
      setLoading(false);
      if (response.data.responseCode === "00") {
        console.log(response.data.data);
        setLoans(response.data.data);
      } else {
        toast.error(response.data.responseMessage)
      }
    } catch (error) {
      setLoading(false);
      //toast.error(error ?);
      console.log(error);

    }
  };
  /*if (error)
    return (
      <Row justify="center" className="my-3">
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{ height: 60 }}
        >
          <Button type="primary" onClick={refresh} >Refresh</Button>
        </Empty>

      </Row>
    );*/

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col >
              <Card title="Pay Back" className="shadow-sm rounded">
                <Table
                  loading={loading}
                  columns={columns}
                  rowKey="id"
                  dataSource={loans}
                />
                <div className="spacer">
                  <DatePicker className="ngdatepicker" onChange={onChange} picker="month" />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default HistoryPage;
