import { Button, Col, Form, Input, Modal, Row } from "antd";
import { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import { validateCard, validateCardOtp } from "../../../api/bank";
import { UserProfileState } from "../../../atoms";
import { showMessage } from "../../../helpers";

interface IProps {
  isOpen?: boolean;
  onCancel: () => void;
  onOK: () => void;
}

const AddCard: FC<IProps> = ({ isOpen, onCancel, onOK }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useRecoilValue(UserProfileState);
  const [stage, setStage] = useState("ENTER_CARD_DETAILS");
  const [otpMessage, setOptMessage] = useState("");
  const [dataRef, setDataRef] = useState("");
  const addNewCard = async () => {
    try {
      if (loading) return;

      setLoading(true);

      if (stage === "ENTER_OTP") {
        const response = await validateCardOtp({
          ...form.getFieldsValue(),
          customerId: user?.data?.msisdn,
          pan: removeSpaces(form.getFieldsValue()["pan"]),
          year: form.getFieldsValue()["monthyear"]?.split("/")[1],
          month: form.getFieldsValue()["monthyear"]?.split("/")[0],
          transactionRef: dataRef,
        });

        console.log("response .daya?> ", response.data);
        showMessage(
          "Operation successful",
          "card added successfully",
          "success"
        );
        onOK();

        setLoading(false);
        return;
      }

      const { monthyear, ...rest } = form.getFieldsValue();

      const response = await validateCard({
        ...rest,
        monthyear,
        customerId: user?.data?.msisdn,
        pan: removeSpaces(form.getFieldsValue()["pan"]),
        year: form.getFieldsValue()["monthyear"]?.split("/")[1],
        month: form.getFieldsValue()["monthyear"]?.split("/")[0],
      });

      showMessage("Operation successful", "card added successfully", "success");

      setLoading(false);

      if (response.data.responseMessage.includes("Kindly enter the OTP")) {
        setOptMessage(response.data.responseMessage);
        setDataRef(response.data.data);
        setStage("ENTER_OTP");
        showMessage(
          "Operation successful",
          response.data.responseMessage,
          "success"
        );
      } else {
        showMessage(
          "Operation successful",
          "card added successfully",
          "success"
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formastAsExpiryCardNumber = (input: string) => {
    return input?.match(/(.{1,2})/g)?.join("/");
  };

  const formatAsCardNumber = (input: string) => {
    return input?.match(/(.{1,4})/g)?.join(" ");
  };

  const removeSpaces = (input: string) => {
    return input?.replace(/\s/g, "");
  };

  const removeSlashCharacter = (input: string) => {
    return input?.replace(/\//g, "");
  };

  const hanldeExpiryOnChange = (e: any) => {
    const value = e.target.value;
    const stripedValue = removeSlashCharacter(value);
    let newValue = formastAsExpiryCardNumber(stripedValue);

    form.setFieldValue("monthyear", newValue);
  };

  const handleOnCardNumberChange = (e: any) => {
    const value = e.target.value;

    const stripedValue = removeSpaces(value);
    const newValue = formatAsCardNumber(stripedValue);

    form.setFieldValue("pan", newValue);
  };

  return (
    <Modal
      style={{ top: 140 }}
      title="Add Debit Card"
      open={isOpen}
      onCancel={onCancel}
      destroyOnClose
      onOk={() => addNewCard()}
      width={800}
      footer={[
        <Button
          onClick={addNewCard}
          key="submit"
          className="atn-btn atn-btn-primary"
          type="primary"
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <h5 className="">Register a debit card for easy repayment</h5>
          <div className="d-flex">
            <div className="debit-card-wrapper">
              <img
                src="https://my.migo.money/assets/icons/logos/Master-card.svg"
                alt="master-card"
              />
            </div>
            <div className="debit-card-wrapper">
              <img
                src="https://my.migo.money/assets/icons/logos/Verve.png"
                alt="verve-card"
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <div className="container-fluid">
        <Form layout="vertical" form={form} name="control-hooks">
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                name="pan"
                label="Card number"
                rules={[{ required: true, message: "Card number is required" }]}
              >
                <Input
                  onChange={handleOnCardNumberChange}
                  placeholder="XXX XXXX XXXX XXXX"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                name="monthyear"
                label="Expiry Date"
                rules={[{ required: true, message: "MM/YY is required" }]}
              >
                <Input onChange={hanldeExpiryOnChange} placeholder="MM/YY" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item name="cvv" label="CVV" rules={[{ required: true }]}>
                <Input type="password" placeholder="***" />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center h-100">
                <span>
                  To verify your card, we will perform a non-refundable test
                  debit of N1
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <Form.Item name="pin" label="PIN" rules={[{ required: true }]}>
                <Input type="password" placeholder="Enter your PIN" />
              </Form.Item>
            </div>
          </div>
          {stage === "ENTER_OTP" && (
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center h-100">
                  <span>{otpMessage}</span>
                </div>
              </div>
              <div className="col-md-6">
                <Form.Item name="otp" label="OTP" rules={[{ required: true }]}>
                  <Input type="text" placeholder="Enter your OTP" />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AddCard;
