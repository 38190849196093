import { Button, Card, Form, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet"
import { useRecoilValue } from "recoil";
import small from "../../../assets/small.png";
import { useNavigate } from "react-router-dom";
import {
  selectedOfferState,
  selectedAccountState,
  UserProfileState,
} from "../../../atoms";
import { getHostedFieldToken } from "../../../api/offer";
import { formatAsCurrency } from "../../../helpers";
import { LeftCircleTwoTone } from "@ant-design/icons";
import { Util } from "../../../utils/Utils";
const moment = require("moment");

const Step2 = () => {
  const user = useRecoilValue(UserProfileState);
  const selectedOffer = useRecoilValue(selectedOfferState);
  const selectedAccount = useRecoilValue(selectedAccountState);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHostedFieldToken();
        console.log(response.data);
        if (!response.data.errorFlag)
          setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        /*    if (error.response.status === 401) {
             Util.handleLogout();
             navigate("/");
           } */
      }
    };

    fetchData();
  }, []);
  return (
    <> {loading ?
      <div className="text-center">
        <Spin />
      </div> :
      <>
        <div className="create-payment-container">
          <h4>Offer details</h4>
          <p>{selectedOffer?.terms}</p>
          <Input
            name="authorization"
            readOnly
            required
            hidden
            id="demo-authorization"
            defaultValue={data?.["access_token"]}
          />
          <Input
            id="demo-merchantCode"
            name="merchantCode"
            readOnly
            hidden
            placeholder="Merchant Code" />
          <Input
            id="demo-channelCode"
            name="channelCode"
            readOnly
            hidden
            required
            defaultValue={"BER"}
            placeholder="Channel Code" />

          <div className="spacer">
            <label>Loan Beneficiary</label>
            <Input
              id="demo-customerId"
              name="customerId"
              readOnly

              required
              defaultValue={user?.msisdn}
              placeholder="Customer Id" />
          </div>
          <div className="spacer">
            <label>Offer Id</label>
            <Input
              id="demo-offerId"
              name="offerId"
              readOnly
              defaultValue={selectedOffer?.offerId}
              placeholder="offerId" />
          </div>
          <div className="spacer">
            <label>Offer Details</label>
            <Input
              id="offered"
              name="offered"
              readOnly
              defaultValue={` ${formatAsCurrency(Number(selectedOffer?.amountOffered) / 100)} to payback ${formatAsCurrency(Number(selectedOffer?.amountPayable) / 100)} in  ${moment(selectedOffer?.expiryDate).format("L")}`}
              placeholder="offerId" />
          </div>
          <Input
            id="demo-providerCode"
            name="providerCode"
            defaultValue={"LME"}
            readOnly
            required
            hidden
            placeholder="providerCode" />
          <Input
            id="demo-transactionReference"
            name="transactionReference"
            readOnly
            required
            hidden
            defaultValue={data?.["transactionId"]}
            placeholder="transactionReference" />
          <h4>Credit Method</h4>
          <div className="spacer">
            <label>Account Name</label>
            <Input
              id="accountName"
              name="accountName"
              readOnly
              required
              defaultValue={selectedAccount?.account_name}
              placeholder="Account Name" />
          </div>
          <div className="spacer">
            <label>Account Number</label>
            <Input
              id="demo-accountNumber"
              name="accountNumber"
              readOnly
              required
              value={selectedAccount?.account_number}
              defaultValue={selectedAccount?.account_number}
              placeholder="Account Number" />
          </div>
          <Input
            id="demo-bankCode"
            name="bankCode"
            readOnly
            required
            hidden
            value={selectedAccount?.bank_code}
            defaultValue={selectedAccount?.bank_name}
            placeholder="bankCode" />
          <div className="spacer">
            <Button type="primary"
              className="atn-btn atn-btn-primary"
              id="create-fields-button"
              block
              disabled={loading}
              loading={loading}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className="payment-form-container" style={{ display: "none", }}>
          <div className="notification-box">
            <div className="content"></div>
          </div>
          <div className="form-header">
            <img alt="1cash" src={small} className="" ></img>

            <div className="info">
              <div className="">{selectedOffer?.offerId}</div>
            </div>

          </div>
          <div className="form-page card-details show">

            <div className="form-control ">
              <label>Card Number</label>
              <div id="cardNumber-container" className="payment-field"></div>
            </div>

            <div className="form-group">

              <div className="form-control ">
                <label>EXP</label>
                <div id="expirationDate-container" className="payment-field"></div>
              </div>

              <div className="form-control ">
                <label>CVV</label>
                <div id="cvv-container" className="payment-field"></div>
              </div>

            </div>
            <div className="button-container ">
              <Button type="primary"
                className="atn-btn atn-btn-primary"
                id="pay-button"
                block
              >
                Pay
              </Button>
            </div>
          </div>
          <div className="form-page pin ">

            <div className="back-control pin-back" id="pin-back-button">
              <LeftCircleTwoTone twoToneColor={"#550273"} style={{ fontSize: "50px" }} />
            </div>

            <div className="form-text ">Please provide your PIN</div>

            <div className="form-control ">
              <label></label>
              <div id="pin-container" className="payment-field"></div>
            </div>

            <div className="button-container ">
              <Button type="primary"
                className="atn-btn atn-btn-primary"
                id="continue-button"
                block
              >
                Continue
              </Button>
            </div>

          </div>
          <div className="form-page otp ">

            <div className="back-control" id="otp-back-button">
              <LeftCircleTwoTone twoToneColor={"#550273"} style={{ fontSize: "50px" }} />
            </div>

            <div className="form-text ">Please input the OTP sent to your mobile number</div>


            <div className="form-control ">
              <label></label>
              <div id="otp-container" className="payment-field"></div>
            </div>

            <div className="button-container ">
              <Button type="primary"
                className="atn-btn atn-btn-primary"
                id="validate-button"
                block
              >
                Validate
              </Button>
            </div>

          </div>
          <div className="form-page cardinal">

            <div className="cardinal-container"></div>

          </div>


        </div>
        <Helmet>
          <script src="https://ils-hosted-fields.interswitchng.com/sdk.js" type="text/javascript" />
          <script src="https://ils-hosted-fields.interswitchng.com/index.js" type="text/javascript" />
        </Helmet></>
    }</>
  );
};

export default Step2;
