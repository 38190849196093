import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useRecoilState } from "recoil";
import { UserProfileState, tokenState, verifyPhoneNumberAtom } from "../atoms";
import { Storage } from "../storage";
import { getUserProfile, login } from "../api/user";
import { Util } from "../utils/Utils";

const VerifyPage = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [value] = useRecoilState(verifyPhoneNumberAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!value.confirmationResult) return navigate("/");
  }, []);

  const [, setUser] = useRecoilState(UserProfileState);
  //const [token, setToken] = useRecoilState(tokenState);

  const handleOnConfirm = async () => {
    try {
      if (loading) return;
      setLoading(true);

      const user = await value.confirmationResult?.confirm(code);
      const response = await login(user?.user.phoneNumber as string);
      console.log(response.data);
     // const response = await getUserProfile(user?.user.phoneNumber as string);
      setUser(response.data.data);
     // setToken(response.data.access_token)
      Storage.saveUser(user);
      Storage.saveToken(response.data.access_token);
      setLoading(false);
      navigate("/dashboard");
    } catch (error: any) {
      setLoading(false);
      toast(error ? error?.message : "Unable to complete action", {
        type: "error",
      });
      console.log("error> ", error);
      return;
    }
  };
  return (
    <div className="login-wrapper-container">
      <div className="login-col right d-flex align-items-center">
        <div className="form-wrapper">
          <div className="container">
            <div className="col-md-12 h-100">
              <div className="login-form h-100">
                <h1 className="login-form-title">Verify your phone number</h1>
                <form className="w-100">
                  <h4 className="login-form-desc text-left">
                    Please enter the verification code that was sent to{" "}
                    {value.phoneNumber}. <Link to="/">Change number</Link>
                  </h4>
                  <div className="d-flex bg-white mb-3 form-input">
                    <input
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="6 digit code"
                    />
                  </div>
                  <Button
                    onClick={handleOnConfirm}
                    className="atn-btn atn-btn-primary"
                    loading={loading}
                  >
                    Verify
                  </Button>
                  <br />
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
