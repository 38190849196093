import { useState, useEffect } from "react";
import { Form, Input, Button, Card, Spin, Row, Select, Image, Empty } from "antd";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { offerAmountState, UserProfileState, offerListState, accountListState, selectedOfferState, selectedAccountState } from "../../../atoms";
import { formatAsCurrency, showMessage } from "../../../helpers";
import { getOffers } from "../../../api/offer";
import { useNavigate } from "react-router-dom";
import { Util } from "../../../utils/Utils";
import { IAccount, IOfferItem } from "../../../api/types";
import { getAccounts } from "../../../api/bank";

const moment = require("moment");
const { Option } = Select;
const Step1 = ({ handleClick }: { handleClick: any }) => {
  const navigate = useNavigate();
  const user = useRecoilValue(UserProfileState);
  const [accounts, setAccounts] = useRecoilState(accountListState);
  const [offers, setOffers] = useRecoilState(offerListState);
  const [loading, setLoading] = useState(false);
  const [amount, setOfferAmount] = useRecoilState(offerAmountState);
  const [visible, setVisible] = useState(false);
  const [showForm, setshowForm] = useState(true);
  const [selectedOffer, setOffer] = useRecoilState(selectedOfferState);
  const [selectedAccount, setAccount] = useRecoilState(selectedAccountState);
  useEffect(() => {
    setLoading(true);
    getAccounts(user?.msisdn).then(response => {
      // Update the state with the fetched data
      console.log(response.data);
      if (!response.data.errorFlag) {
        if (response.data.data.length > 0) {
          const accounts = response.data?.data as IAccount[];
          setAccounts(accounts);
        }
      } else {
        toast.error(response.data.responseMessage);
      }
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
        console.log(error);
        if (error.response.status === 401) {
          Util.handleLogout();
          navigate("/");
        }
        toast.error(error ? error?.message : "Unable to complete action");
      });
  }, [setAccounts]);

  /* const [banks, setBanks] = useRecoilState(banksState);
   useEffect( () => {
       setLoading(true);
     getBanks().then(response => {
         // Update the state with the fetched data
         setBanks(response.data);
         //setBanks([...banks, response.data)]);
       })
       .catch(error => {
         toast(error ? error?.message : "Unable to complete action", {
           type: "error",
         });
         console.log(error);
         // Handle error
         console.error('Error fetching data:');
       });
       setLoading(false);
   }, [setBanks]);

  const onFinish = async (values: String) => {
    console.log('Form values:', values);
    // onNextStep(values);
    try {
      if (loading) return;
      setLoading(true);
      const response = await getOffers(amount, process.env.NODE_ENV === "production" ? user?.msisdn : "2347036264305");
      console.log(response.data.data)
      if (response.data.errorFlag == false) {
        const listOfOffers = response.data?.data as IOfferItem[];
        setOffers(listOfOffers);
        setVisible(true);
        setshowForm(false);
        // return response.data?.data as IOfferItem[];
      } else {
        toast.error(response.data.responseMessage);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigate("/");
      }
      var errorMsg = extractErrors(error);
      toast(errorMsg, {
        type: "error",
      });
      return;
    }
    // setOfferAmount("");
  };
  const handleClick = () => {
    if (!selectedOffer && selectedOffer == null)
      return toast("Please select an offer to continue", {
        type: "error",
      });
    if (!selectedAccount && selectedAccount == null)
      return toast("Please select destination account", {
        type: "error",
      });
  }; */
  return (
    <div className="small-spacer">
      {/* loading && <div className="overlay"><Spin /></div> */}
      <Card title="View Offers" className="shadow-sm rounded">
        {offers.length < 1 ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : (
          <Form layout="vertical"  >
            <Form.Item
              name="offer"
              label="Select Offer"
              rules={[{ required: true }]}
            >
              <Select
                //style={{ width: 500 }}
                placeholder="Select an offer"
                size="large"
                onChange={(e) => {
                  const offer = offers.find((item: IOfferItem) => item.offerId === e);
                  if (!offer)
                    return showMessage("Offer", "Offer not found", "error");
                  setOffer(offer);
                }
                }
              >
                {offers.map(offer => (
                  <Option key={offer.offerId} value={offer.offerId}>
                    <Row>
                      <Image width={30} height={40}
                        src="https://www.iconpacks.net/icons/2/free-shopping-bag-icon-2041-thumb.png"
                      />
                      <div>
                        <span className="fs-sm">
                          <strong>
                            Amount - {formatAsCurrency(Number(offer.amountOffered) / 100)}
                          </strong>
                        </span>
                        <h3 className="fs-sm">
                          Payback {formatAsCurrency(Number(offer.amountPayable) / 100)} in  {moment(offer.expiryDate).format("L")}
                        </h3>
                      </div>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="bank"
              label="Select destination account"
              rules={[{ required: true }]}
            >
              <Select
                className="custom-select"
                //style={{ width: 500, }}
                loading={loading}
                size="large"
                notFoundContent={<Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{ height: 60 }}
                >
                  <Button type="primary" onClick={() => navigate("/dashboard/bank-accounts")}>Add Bank</Button>
                </Empty>}
                placeholder="Select destination account"
                onChange={(e) => {
                  const account = accounts.find((item: IAccount) => item.account_number === e);
                  if (!account)
                    return showMessage("Account", "Account not found", "error");
                  setAccount(account);
                }
                }
              >
                {accounts.map(account => (
                  <Option key={account.account_number} value={account.account_number}>
                    <Row>
                      <Image width={30} height={40}
                        src="https://www.iconpacks.net/icons/2/free-shopping-bag-icon-2041-thumb.png"
                      />
                      <div>
                        <span className="fs-sm">
                          <strong>
                            {account.bank_name}
                          </strong>
                        </span>
                        <h3 className="fs-sm">
                          {account.account_name} {account.account_number}
                        </h3>
                      </div>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary"
                htmlType="submit"
                className="atn-btn atn-btn-primary"
                onClick={handleClick}
                loading={loading}
                disabled={loading}
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>

    </div >
  );
};

/*
<div className="row">
        <div className="col-md-12">
          <br />
          <p className="">
            <strong>Congratulations!</strong> You have been approved for a loan.
            Please select a loan offer below
          </p>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-md-12">
          <h2 className="league-title">Select a loan amount:</h2>
          <h2 className="league-title">&#8358;{amount}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Stepper />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h2 className="league-title">Duration</h2>
          <div className="box">14 days</div>
        </div>
      </div>
*/

export default Step1;
