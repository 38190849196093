import { initializeApp, FirebaseApp, getApps } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { firebaseConfig } from "../constants/firebase";

let app!: FirebaseApp;

app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const signUserWithPhoneNumber = async (
  phoneNumber: string,
  recaptchaVerifier: RecaptchaVerifier
) => {
  return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
};

export { signUserWithPhoneNumber, app, auth, signOut };
