import { notification } from "antd";
import { IconType } from "antd/lib/notification";
import { Util } from "../utils/Utils";

export const extractErrors = (error: any) => {
  if (error.response) {
    if (error.response.data) {
      /* if (typeof error.response.data.error === "string")
       return [error.response.data.error];*/
      if (error.response.data.responseMessage) {
        return [error.response.data.responseMessage];
      } else if (
        error.response.data.error &&
        error.response.data.error.length > 0
      ) {
        return error.response.data.error.map((error: any) => {
          return error.message;
        });
      } else if (error.response.data.message) {
        return [error.response.data.message];
      } else {
        return error.response.statusText;
      }
    } else {
      return [error.response.statusText];
    }
  } else if (error.request) {
    // Request was made but no response received
    console.error('No response received:', error.request);
    return error.request;
  } else {
    // Something else went wrong
    console.error('Error:', error.message);
    return error.message;
  }
};

export const showError = (error: any) => {
  console.log(error);
  if (error.request.readyState === 4 && error.code === "ERR_NETWORK") {
    showMessage("Network Error", "please try again later", "error");
  }
  if (error.response.status === 401) {
    console.log("am here ..................................................");
    showMessage("Session Expire", "please login to continue", "error");
    Util.handleLogout();
  }
};

export const showMessage = (
  title: any,
  description: any,
  type: IconType | undefined
) => {
  notification.open({
    type: type || "success",
    message: title,
    description: description,
  });
};

export const formatAsCurrency = (number: any) => {
  return Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(number);
};

export const currencyBackToNumber = (formattedCurrency: any) => {
  return parseFloat(formattedCurrency.replace(/[^0-9.-]+/g, ''));
};

export function thousandSeparator(value: any) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
