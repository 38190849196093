import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Spin, Form, Input, Row, Typography, Modal } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserProfileState, selectLoan } from "../../../atoms";
import { toast } from "react-toastify";
import { Util } from "../../../utils/Utils";
import monnify from "../../../assets/monnify.png";
import interswitchs from "../../../assets/interswitchs.png";
import transfer from "../../../assets/transfer.png";
import ussd from "../../../assets/ussd.png";
import { updateUserEmail } from "../../../api/user";
import { currencyBackToNumber, extractErrors, formatAsCurrency, thousandSeparator } from "../../../helpers";
import { debitCustomerAccount, getPaymentAccount, getTransactionReference } from "../../../api/offer";
const moment = require("moment");

const PaymentPage = () => {
  const navigation = useNavigate();
  const [emailform] = Form.useForm();
  const [paymentform] = Form.useForm();
  const [open, setOpen] = useState(false);
  const item = useRecoilValue(selectLoan);
  const [on, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(UserProfileState);
  const [modalText, setModalText] = useState(<></>);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [amountPayable, setAmountPayable] = useState(item?.amountPayable);

  useEffect(() => {
    if (item === "Undefined" || item === null) {
      navigation(-1);
    }
    paymentform.setFieldValue("amount", Number(item.amountPayable) / 100);
  }, [paymentform, item]);

  const showTransferModal = async () => {
    try {
      setLoading(true);
      const response = await getPaymentAccount(item.providerLoanId, amountPayable);
      console.log(response.data)
      if (response.data.errorFlag === false) {
        setLoading(false);
        var texts = <>Kindly transfer the sum of  <b>{formatAsCurrency(Number(amountPayable / 100))}</b> to below account details:<br /><br />Account Number: <b>{response.data.data.accountNumber}</b><br />Bank Name:   <b>{response.data.data.bankName}</b>
          <br /><br /><b>NOTE:</b> Please enter <b>{item.loanReferenceId}</b> as trasfer description for easy tracking and closure.
        </>
        setModalText(texts);
        setOpen(true);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      var errorMsg = extractErrors(error);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigation("/");
      }
      toast.error(errorMsg);
      return;
    }
  };
  const payWithMonnify = async () => {
    console.log(item);
    console.log(user.email);
    if (!validEmail(user.email)) return setEmailModal(true);
    try {
      setLoading(true);
      const response = await getTransactionReference(user.msisdn, item.providerLoanId, amountPayable);
      setLoading(false);
      console.log(response.data)
      if (response.data.errorFlag === false) {
        window.payWithMonnify(item, response.data.data, user)
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigation("/");
      }
      var errorMsg = extractErrors(error);
      toast.error(errorMsg);
      return;
    }
  }
  const payWithInterswitchDebit = async (values: string) => {
    console.log('Form values:', values);
    try {
      setLoading(true);
      const response = await debitCustomerAccount(user.msisdn, item, amountPayable);
      setLoading(false);
      console.log(response.data)
      if (response.data.errorFlag === false) {
        toast.success(response.data.responseMessage);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigation("/");
      }
      var errorMsg = extractErrors(error);
      toast.error(errorMsg);
      return;
    }
  };
  const onFinishUpdateEmail = async (values: string) => {
    console.log('Form values:', values);
    try {
      setConfirmLoading(true);
      const response = await updateUserEmail(values);
      console.log(response.data)
      if (response.data.errorFlag === false) {
        toast(response.data.responseMessage, {
          type: "success",
        });

      } else {
        toast(response.data.responseMessage, {
          type: "error",
        });
      }
      setEmailModal(false);
    } catch (error: any) {
      setConfirmLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        Util.handleLogout();
        navigation("/");
      }
      var errorMsg = extractErrors(error);
      toast(errorMsg, {
        type: "error",
      });
      return;
    }
    setConfirmLoading(false);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const validEmail = (email: string) => {
    if (email === null || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) return false;
    return true;
  }
  return (
    <div className="small-spacer">
      {loading && <div className="overlay"><Spin /></div>}
      <div className="form-width">
        <Row>
          <Col span={18}>
            <Card className="shadow-sm rounded">
              <Typography.Title
                level={4}
                onClick={() => navigation(-1)}
                className="back-btn"
              >
                <LeftOutlined />
                Back
              </Typography.Title>
              <div>
                <Form form={paymentform} layout="vertical" onFinish={payWithInterswitchDebit}>
                  <div className="create-payment-container-y">
                    <h4>Loan details</h4>
                    <div className="spacer">
                      <label>Loan Id</label>
                      <Input
                        id="loanReferenceId"
                        name="loanReferenceId"
                        readOnly
                        defaultValue={item?.loanReferenceId}
                        placeholder="loanReferenceId" />
                    </div>
                    <div className="spacer">
                      <label>Due Date</label>
                      <Input
                        id="dueD"
                        name="dueD"
                        readOnly
                        defaultValue={`${moment(item?.dueDate).format("L")}`}
                        placeholder="dueD" />
                    </div>
                    <div className="spacer">
                      <label>Amount Borrowed</label>
                      <Input
                        id="amount"
                        name="amount"
                        readOnly
                        defaultValue={formatAsCurrency(Number(item?.amount) / 100)}
                        placeholder="amount" />
                    </div>
                    <div className="spacer">
                      <label>Amount Payable</label>
                      <Input
                        id="amountP"
                        name="amountP"
                        defaultValue={formatAsCurrency(Number(amountPayable) / 100)}
                        onChange={(e) => {
                          console.log(currencyBackToNumber(e.target.value) * 100);
                          setAmountPayable(`${currencyBackToNumber(e.target.value) * 100}`)
                        }
                        }
                        value={formatAsCurrency(Number(amountPayable) / 100)}
                        placeholder="amountP" />
                    </div>
                  </div>
                </Form>
                <Row gutter={[16, 0]}>
                  <Col>
                    <Col>
                      <Button className="atn-btn" onClick={payWithMonnify}>
                        <img src={monnify} width={50} alt="Monnify" />
                      </Button>
                      <Form form={emailform} layout="vertical" onFinish={onFinishUpdateEmail} >
                        <Modal
                          title="Enter Your Email Address"
                          open={on}
                          centered
                          maskClosable={false}
                          onOk={() => emailform.submit()}
                          confirmLoading={confirmLoading}
                          onCancel={() => setEmailModal(false)}>
                          {
                            <Form.Item
                              name="email"
                              rules={[{ required: true, message: 'Please input your email address' }, { type: 'email', warningOnly: false, message: 'Please enter a valid email address' },]}
                            >
                              <Input
                                placeholder="name@example.com"
                              />
                            </Form.Item>
                          }
                        </Modal>
                      </Form>
                    </Col>
                  </Col>
                  <Col>
                    <Button className="atn-btn">
                      <img src={interswitchs} onClick={() => paymentform.submit()} width={50} alt="InterSwitch" />
                    </Button>
                  </Col>
                  <Col>
                    <Button className="atn-btn" onClick={showTransferModal}>
                      <img src={transfer} width={50} alt="pay with transfer" />
                    </Button>
                    <Modal
                      title="Loan Repayment Via Transfer"
                      open={open}
                      centered
                      maskClosable={false}
                      onOk={handleOk}
                      confirmLoading={confirmLoading}
                      onCancel={() => setOpen(false)}>
                      <p>{modalText}</p>
                    </Modal>
                  </Col>
                  {/* <Col>
                    <Button className="atn-btn" onClick={showTransferModal}>
                      <img src={ussd} width={50} alt="InterSwitch" />
                    </Button>
                  </Col> */}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentPage;
