import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./dashboard.css";
import small from "../../assets/small.png";
import { useEffect, useState } from "react";
import { Storage } from "../../storage";
import { useRecoilState } from "recoil";
import { UserProfileState, tokenState } from "../../atoms";
import { CloseOutlined, MenuOutlined, HomeOutlined, BankOutlined, HddOutlined, QuestionCircleOutlined, BoxPlotOutlined, LogoutOutlined } from "@ant-design/icons";
import { Row, Space } from "antd";

const DashboardHOC = () => {
  const navigate = useNavigate();
  const [token, setToken] = useRecoilState(tokenState);
  const [user, setUser] = useRecoilState(UserProfileState);

  const location = useLocation();

  useEffect(() => {
    setisOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", () => setisOpen(false));
  }, []);

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (!user) return navigate("/");
  }, [user]);

  const handleLogout = () => {
    Storage.removeUser();
    setUser(null);
    setToken(null);
    Storage.removeSessionItems();
  };
  return (
    <div className="dashboard-wrapper">
      <div className="dashbarod-lg-menu">
        <nav className="lg-menu-wrapper menu">
          <div className="logo-lg">
            <img alt="1cash" src={small} height={10} sizes="10" />
          </div>
          <ul className="lg-menu-list">
            <li>
              <HomeOutlined title="loan Request" style={{ fontSize: '2em' }} />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to=""
                end
              >
                Request Loan
              </NavLink>
            </li>
            <li>
              <BankOutlined title="history" style={{ fontSize: '2em' }} />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="bank-accounts"
                end
              >
                Banks
              </NavLink>
            </li>

            {/*} <li>
              <img
                src="https://my.migo.money/assets/icons/menu/ic_loan_naira.svg"
                alt="payack loan"
              />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="payback"
                end
              >
                Payback
              </NavLink>
              </li> */}
            <li>
              <HddOutlined title="Add Bank Account" style={{ fontSize: '2em' }} />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="history"
                end
              >
                Loan History
              </NavLink>
            </li>
            {/* <li>
              <img
                src="https://my.migo.money/assets/icons/menu/ic_loan_naira.svg"
                alt="loan"
              />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="payment-cards"
                end
              >
                Cards
              </NavLink>
            </li>*/}
            <li>
              <QuestionCircleOutlined title="Frequently Asked Questions" style={{ fontSize: '2em' }} />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="faq"
                end
              >
                FAQ
              </NavLink>
            </li>
            <li>
              <BoxPlotOutlined title="Terms and Aggrement" style={{ fontSize: '2em' }} />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="terms"
                end
              >
                Terms and conditions
              </NavLink>
            </li>
          </ul>
        </nav>
        <ul className="logout-menu-wrapper">
          <li onClick={handleLogout}>
            <LogoutOutlined title="Logout" style={{ fontSize: '2em', marginInlineEnd: 10 }} />
            <Space size={20} direction="horizontal" />
            Log out
          </li>
        </ul>
      </div>
      <div className="dashboard-mb-menu">
        <div className="logo-lg">
          <img alt="1cash" src={small} />
        </div>
        <MenuOutlined
          onClick={() => setisOpen((state) => !state)}
          color="#fff"
        />
      </div>
      <div className="main-content mt-5">
        <Outlet />
      </div>
      <div className={`mobile-menu ${isOpen ? "open" : "closed"}`}>
        <Row justify="end" className="mb-3 py-5 px-3">
          <CloseOutlined onClick={() => setisOpen((state) => !state)} color="#fff" />
        </Row>

        <ul className="lg-menu-list">
          <li>
            <HomeOutlined title="loan Request" style={{ fontSize: '2em' }} />
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
              to=""
              end
            >
              Request Loan
            </NavLink>
          </li>
          <li>
            <BankOutlined title="history" style={{ fontSize: '2em' }} />
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
              to="bank-accounts"
              end
            >
              Banks
            </NavLink>
          </li>

          {/*} <li>
              <img
                src="https://my.migo.money/assets/icons/menu/ic_loan_naira.svg"
                alt="payack loan"
              />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="payback"
                end
              >
                Payback
              </NavLink>
              </li> */}
          <li>
            <HddOutlined title="Add Bank Account" style={{ fontSize: '2em' }} />
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
              to="history"
              end
            >
              Loan History
            </NavLink>
          </li>
          {/* <li>
              <img
                src="https://my.migo.money/assets/icons/menu/ic_loan_naira.svg"
                alt="loan"
              />
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active-link" : undefined
                }
                to="payment-cards"
                end
              >
                Cards
              </NavLink>
            </li>*/}
          <li>
            <QuestionCircleOutlined title="Frequently Asked Questions" style={{ fontSize: '2em' }} />
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
              to="faq"
              end
            >
              FAQ
            </NavLink>
          </li>
          <li>
            <BoxPlotOutlined title="Terms and Aggrement" style={{ fontSize: '2em' }} />
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
              to="terms"
              end
            >
              Terms and conditions
            </NavLink>
          </li>
          <li onClick={handleLogout}>
            <LogoutOutlined title="Logout" style={{ fontSize: '2em', marginInlineEnd: 10 }} />
            <Space size={20} direction="horizontal" />
            Log out
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardHOC;
