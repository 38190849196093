import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Row,
  Spin,
  Table,
} from "antd";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { paybackLoan } from "../../../api/offer";
import { selectLoan, UserProfileState } from "../../../atoms";
import { Util } from "../../../utils/Utils";
const moment = require("moment");

const PaybackPage = () => {
  const refresh = ()=>window.location.reload();
  const user = useRecoilValue(UserProfileState);
  const [, setLoan] = useRecoilState(selectLoan);
  const { isLoading, error, data } = useQuery({
    queryKey: ["loans", user?.msisdn],
    queryFn: async () => {
      console.log(user?.msisdn);
      const response = await paybackLoan(user?.msisdn);
      return response.data;
    },
  });
  const columns = useMemo(
    () => [
      {
        title: "Provider Name",
        dataIndex: "providerCode",
        key: "providerCode",
      },
      {
        title: "Loan ID",
        dataIndex: "loanReferenceId",
        key: "loanReferenceId",
      },
      {
        title: "Customer ID",
        dataIndex: "customerId",
        key: "customerId",
      },
      {
        title: "Amount Borrowed",
        dataIndex: "amount",
        key: "amount",
        render: (keyValue: string) =>
          Util.formatAsCurrency(Number(keyValue) / 100),
      },
      {
        title: "Amount Payable",
        dataIndex: "amountPayable",
        key: "amountPayable",
        render: (keyValue: string) =>
          Util.formatAsCurrency(Number(keyValue) / 100),
      },
      {
        title: "Request Date",
        dataIndex: "dateCreated",
        key: "dateCreated",
        render:(value:Number)=>moment(value).format("dd.mm.yyyy")
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        render:(value:Number)=>moment(value).format("dd.mm.yyyy")
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Action",
        dataIndex: "",
        render: (keyValue: any, data: any) => (
          <Link onClick={() => setLoan(data)} to={`${data.id}/pay`}>
            <Button className="atn-btn atn-btn-primary">Pay</Button>
          </Link>
        ),
      },
    ],
    []
  );

  if (error)
  return (
    <Row justify="center" className="my-3">
      <Empty 
       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
       imageStyle={{ height: 60 }}
>
  <Button type="primary" onClick={refresh} >Refresh</Button>
</Empty>

    </Row>
  );
  const loans: any[] = data?.data || [];

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
         {loans.length>0 ?<Row>
            <Col span={24}>
              <Card title="Pay Back" className="shadow-sm rounded">
                <Table
                  loading={isLoading}
                  columns={columns}
                  dataSource={loans}
                />
              </Card>
            </Col>
          </Row> :
           <Row justify="center" className="my-3">
           <Empty 
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
     >
       <Button type="primary" onClick={refresh} >Refresh</Button>
     </Empty>
         </Row>
         } 
        </div>
      </div>
    </div>
  );
};

export default PaybackPage;
