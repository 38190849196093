import { Button, Card, Row, Select, Form, Input, Space } from "antd";
import { Util } from "../../utils/Utils";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { banksState, selectedAccountState, selectedBankState, UserProfileState } from "../../atoms";
import { IBankItem } from "../../api/types";
import { extractErrors, showMessage } from "../../helpers";
import { toast } from "react-toastify";
import { addAccountNumber, getBanks, validateBank } from "../../api/bank";
import { BankFilled } from "@ant-design/icons";
const { Option } = Select;

const BankAccount = () => {
  const user = useRecoilValue(UserProfileState);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useRecoilState(banksState);
  const [, setBank] = useRecoilState(selectedBankState);
  const [selectedAccount, setAccount] = useRecoilState(selectedAccountState);
  useEffect(() => {
    setLoading(true);
    getBanks().then(response => {
      // Update the state with the fetched data
      console.log(response.data);
      if (!response.data.errorFlag) {
        const banks = response.data?.data as IBankItem[];
        setBanks(banks);
      } else {
        toast.warning(response.data.responseMessage);
      }
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.error(error ? error?.message : "Unable to complete action");
      });
  }, [setBanks]);

  /* const { isLoading, error, data } = useQuery({
     queryKey: ["userProfile", user?.msisdn],
     queryFn: async () => {
       const response = await getUserProfile(user?.data?.msisdn);
       return response.data;
     },
   });
   const [isAddBankOpen, setAddBankOpen] = useState(false);
   const onCancel = () => {
     setAddBankOpen(false);
   };
 
   const [selectedBank, setBank] = useRecoilState(selectedBankState);
 
   const queryClient = useQueryClient();
 
   const onOk = () => {
     queryClient.invalidateQueries({
       queryKey: ["userProfile", user?.msisdn],
     });
     setAddBankOpen(false);
   };
   const renderBanks = () => {
     return data.data.banks.map((item: any, i: any) => (
       <Col key={i} xl={6} sm={24}>
         <Card
           onClick={() => setBank(item)}
           className={`shadow-sm rounded cursor-pointer ${selectedBank?.id === item.id ? "selected" : ""
             }`}
         >
           <div className="row">
             <div className="col-sm-6 col-xs-6 col-md-4">
               <img src={item.bankIcon || bankIcon} alt="bank" />
             </div>
             <div className="col-md-8 col-xs-6 col-sm-6">
               <h3 className="fs-sm">{item.accountName}</h3>
               <h3 className="fs-md">
                 <strong>{item.accountNumber}</strong>
               </h3>
               <h6 className="fs-sm">
                 <em>{item.bankName}</em>
               </h6>
             </div>
           </div>
         </Card>
       </Col>
     ));
   };*/
  const validateAccountNumber = async (bank: IBankItem) => {
    try {
      if (loading) return;
      setLoading(true);
      const payload = {
        "destinationBankCode": bank.sortcode,
        "destinationBankAccount": form.getFieldValue("accountNumber"),
        "customerId": user?.msisdn

      }
      const response = await validateBank(payload);
      console.log(response.data);
      if (response.data.errorFlag === false) {
        setAccount(response.data?.data);
        form.setFieldValue("accountName", selectedAccount?.account_name);
      } else {
        toast.error(response.data.responseMessage);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 401) {
        Util.handleLogout();
      }
      var errorMsg = extractErrors(error);
      toast.error(errorMsg);
      return;
    }
  };
  const submitAddBank = async (values: String) => {
    console.log('Form values:', values);
    try {
      if (loading) return;
      setLoading(true);
      const payload = {
        "firstname": selectedAccount?.account_name,
        "lastname": selectedAccount?.account_name,
        "middlename": selectedAccount?.account_name,
        "account_name": selectedAccount?.account_name,
        "account_number": selectedAccount?.account_number,
        "phone": selectedAccount?.phone_number,
        "bvn": selectedAccount?.bvn,
        "bank_name": selectedAccount?.bank_name,
        "bank_code": selectedAccount?.bank_code,

      }
      const response = await addAccountNumber(payload);
      console.log(response.data);
      if (response.data.errorFlag === false) {
        toast.success(response.data.responseMessage);
      } else {
        toast.error(response.data.responseMessage);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 401) {
        Util.handleLogout();
      }
      var errorMsg = extractErrors(error);
      toast.error(errorMsg);
      return;
    }
    // setOfferAmount("");
  };
  function filterOption(input: any, option: any) {
    console.log(option);
    // Customize the filter logic here
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return (

    <div className="loan-apply-wrapper">

      <div className="container ">
        <div className="row w-100 mb-3">
          <div className="col-md-12 w-100">
            <div className="small-spacer">
              <div className="form-width">
                <Card title="Add Bank Account" className="rounded shadow-sm">
                  <Form form={form} layout="vertical" onFinish={submitAddBank} >
                    <Form.Item
                      name="accountNumber"
                      label="Enter Account Number"
                      rules={
                        [
                          { required: true, message: 'Please input your Account Number!' },
                          { min: 10, message: 'Account Number must be minimum 10 digits.' },
                          { max: 10, message: 'Account Number must be 10 digits.' },
                        ]}
                    >
                      <Input
                        placeholder="Enter Account Number"
                        size="small"
                        onChange={(e) =>
                          console.log(e.target.value)
                          // setOfferAmount(`${Number(e.target.value) * 100}`)
                        }
                      // value={accountNumber}
                      />
                    </Form.Item>

                    <Form.Item
                      name="bank"
                      label="Select Bank"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select a bank"
                        size="large"
                        loading={loading}
                        disabled={loading}
                        optionFilterProp="label"
                        showSearch
                        allowClear
                        filterOption={filterOption}
                        onChange={(e) => {
                          const bank = banks.find((bank: IBankItem) => bank.sortcode === e);
                          if (!bank)
                            return showMessage("Bank", "Bank not found", "error");
                          form.validateFields(["accountNumber"]).then(
                            value => {
                              console.log(bank);
                              setBank(bank);
                              validateAccountNumber(bank)
                              return;
                            }
                          ).catch(error => {
                            console.log(error);
                            toast.error(error ? error?.message : "Unable to complete action");
                            return;
                          }

                          )
                        }
                        }
                      >
                        {banks.map(bank => (
                          <Option key={bank.id} value={bank.sortcode} label={bank.name} >
                            <Row>
                              <BankFilled style={{ fontSize: '2em', marginInlineEnd: 10 }} />
                              <Space size={20} direction="horizontal" />
                              <div>
                                <span className="fs-sm">
                                  <strong>
                                    {bank.name}
                                  </strong>
                                </span>
                              </div>
                            </Row>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="accountName"
                      label="Enter Account Name"
                      rules={[{ required: true }]}

                    >
                      <Input
                        placeholder="Account name"
                        disabled
                        value={selectedAccount?.account_name}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary"
                        htmlType="submit"
                        className="atn-btn atn-btn-primary"
                        loading={loading}
                        disabled={loading}
                        block
                      >
                        Add Account
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            </div >
          </div>
        </div>
      </div>
    </div >
    /*<div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row justify="end">
            <Col>
              <Button
                onClick={() => setAddBankOpen(true)}
                className="atn-btn atn-btn-primary"
              >
                Add Bank
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="py-3"></div>
      <div className="row">
        <div className="col-md-12">
          <Row gutter={[16, 20]}>{renderBanks()}</Row>
        </div>
      </div>
      <AddBank isOpen={isAddBankOpen} onCancel={onCancel} onOK={onOk} />
    </div>*/
  );
};

export default BankAccount;
