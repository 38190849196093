import { showError } from "../helpers";
import { Storage } from "../storage";
import { Util } from "../utils/Utils";
import { Axios } from "./base";

export const getOffers = (amount: string, customerNumber: string) => {
  var token = Storage.getToken();
  return Axios.post(`getOffers?phoneNumber=${customerNumber}&amount=${amount}`, {
    phoneNumber: customerNumber,
    amount: Util.convertStringToNumber(amount)
  }, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
export const getHostedFieldToken = () => {
  var token = Storage.getToken();
  return Axios.get(`transaction/token`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
export const acceptOfferApi = (payload: any) => {
  var token = Storage.getToken();
  return Axios.post("accept/offer", payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const paybackLoan = (phoneNumber: string) => {
  var token = Storage.getToken();
  phoneNumber =
    process.env.NODE_ENV === "production" ? phoneNumber : "2347036264305";
  console.log(token);
  return Axios.get(`loan/payback/${phoneNumber}`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const getLoanHistory = (phoneNumber: string, startdate: string, enddate: string) => {
  var token = Storage.getToken();
  console.log(phoneNumber);
  phoneNumber =
    process.env.NODE_ENV === "production" ? phoneNumber : "2347036264305";
  const payload = {
    "startDate": startdate,
    "endDate": enddate,
    "channelCode": "BER"
  };
  return Axios.post(`loan/history/${phoneNumber}`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
export const debitCustomerAccount = (phoneNumber: string, loan: any, amount: string) => {
  var token = Storage.getToken();
  console.log(phoneNumber);
  phoneNumber =
    process.env.NODE_ENV === "production" ? phoneNumber : "2347036264305";
  const payload = {
    "customerId": phoneNumber,
    "amount": amount,
    "loanId": loan.providerLoanId
  };
  return Axios.post(`loan/${loan.providerLoanId}/debit`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
export const getTransactionReference = (phoneNumber: string, loanId: string, amount: string) => {
  var token = Storage.getToken();
  console.log(phoneNumber);
  phoneNumber =
    process.env.NODE_ENV === "production" ? phoneNumber : "2347036264305";
  const payload = {
    "customerId": phoneNumber,
    "amount": amount,
    "loanId": loanId
  };
  return Axios.post(`transaction/${loanId}/reference`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
export const getPaymentAccount = (loanId: string, amount: string) => {
  var token = Storage.getToken();
  const payload = {
    "loanId": loanId,
    "amount": amount
  };
  return Axios.post(`payback/internal/account/${loanId}`, payload, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};
