import { atom, RecoilState } from "recoil";
import { IAccount, IBankItem, IOfferItem } from "../api/types";
import implementPersist from "../utils/implement-persist";
import { VerifyPhoneNumberAtomDTO } from "./types";

const verifyPhoneNumberAtom = atom({
  key: "verifyPhoneNumber",
  default: {
    phoneNumber: "",
    confirmationResult: null,
  },
}) as RecoilState<VerifyPhoneNumberAtomDTO>;
const UserProfileState = atom({
  key: "UserProfile",
  default: "",
  effects_UNSTABLE: implementPersist("UserProfile"),
});
const tokenState = atom({
  key: "token",
  default: "",
  effects_UNSTABLE: implementPersist("token"),
}) as RecoilState<string | null>;

const offerAmountState = atom({
  key: "offerAmmount",
  default: "",
}) as RecoilState<string>;

const offerListState = atom<IOfferItem[]>({
  key: 'offerListState',
  default: [],
});
const selectedOfferState = atom({
  key: "selectedOffer",
  default: null,
}) as RecoilState<IOfferItem | null>;

const accountListState = atom({
  key: 'accountListState',
  default: [],
}) as RecoilState<IAccount[] | []>;

const selectedAccountState = atom({
  key: "selectedAccount",
  default: null,
}) as RecoilState<IAccount | null>;

const banksState = atom({
  key: 'banks',
  default: [],
}) as RecoilState<IBankItem[] | []>;

const selectedBankState = atom({
  key: "selectedBank",
  default: null,
}) as RecoilState<IBankItem | null>;



const selectedCardState = atom({
  key: "selectedCard",
  default: null,
}) as RecoilState<any>;

const selectLoan = atom({
  key: "selectedLoan",
  default: null,
}) as RecoilState<any>;

const loansList = atom<any[]>({
  key: 'loansState',
  default: [],
});
export {
  tokenState,
  verifyPhoneNumberAtom,
  offerAmountState,
  selectedOfferState,
  UserProfileState,
  selectedBankState,
  selectedCardState,
  selectLoan,
  banksState,
  offerListState,
  accountListState,
  selectedAccountState,
  loansList,
};
